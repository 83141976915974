<template>
  <div style="opacity: 0;">
    111
  </div>
</template>
<script>

export default ({
  name: 'Qrcode',
  data() {
    return{
      
    }
  },
  created(){
    this.$emit('controlShow', true);
    this.download();
  },
  methods:{
    download(){
      var u = navigator.userAgent;
      if (u.toLowerCase().match(/MicroMessenger/i) == 'micromessenger') {
        alert('请点击右上角【...】，选择在浏览器中打开，或直接使用浏览器扫描二维码。');
        return false;
      } else {
        if (/(iPhone|iPad|iPod|iOS)/i.test(u)) { //苹果手机
          window.location.href = 'https://apps.apple.com/cn/app/%E5%AE%89%E8%83%9C%E8%B4%A2%E5%AF%8C/id1602800589';
        } else {
          window.location.href = 'https://oss.0790jiaxiao.com/HS/software/hs_9999.apk';
        }
      }
    }
  }
})
</script>

